.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

:root {
  /* SurveyJS Creator V2 */
  /* --test-color: #f400eb; */
  /* Leave Default */
  /* --primary-foreground: var(--test-color); */
  /* --secondary-foreground: var(--test-color); */
  /* --background-for-editors: var(--test-color); */
  /* --foreground-disabled: var(--test-color); */
  /* --border: var(--test-color); */
  /* --red-light: var(--test-color); */
  /* --green-light: var(--test-color); */
  /* --red: var(--test-color); */

  --green: #113483;
  --primary: #113483;
  --primary-light: #cbcbcb;
  --secondary: #676666;
  --secondary-light: #c9c8c8;
  --secondary-back-light: #eaeaea;
  --background: #ffffff;
  --background-dim: #f6f6f6;
  --foreground: #000000;
  --foreground-light: #000000;
  --border-inside: #262626;
  --box-shadow-color: #000029;
  --disabled: #000;
  --foreground-disabled: #555;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;
}

.button-link:hover {
  text-decoration: underline;
}

.grid-wrapper-root {
  width: 100%;
  display: flex;
  padding: 5px;
  justify-content: center;
}

/* .save-button {
    width: 85%;
    min-height: 50px;
    color: white;
    background-color: #113483;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  } */

.save-wrapper {
  background-color: var(--background-dim);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
}

.save-text-box {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: 0.75rem;
  margin: 0 2rem 1rem;
}

.svc-creator {
  height: calc(100vh - 70px);
}

.spg-checkbox--checked .spg-checkbox__rectangle:after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='blue' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 10.8L0.599998 5.4L2 4L6 8L14 0L15.4 1.4L6 10.8Z' fill='%2401B394'/%3E%3C/svg%3E%0A");
}

.sv-list__item--selected {
  background-color: var(--primary) !important;
}

.sv-popup__button.sv-popup__button--apply {
  background-color: var(--primary) !important;
}

.sv-popup__footer-item.sv-popup__button.sv-popup__button--cancel {
  color: var(--primary) !important;
}

.sv-popup__button:hover {
  box-shadow: var(--primary) 0px 0px 0px 2px !important;
}

.sd-container-modern__title {
  box-shadow: none !important;
}

.spg-panel__content {
  box-shadow: none;
  background-color: white;
}

.spg-root-modern {
  flex: 1;
  background-color: white;
}

.svc-side-bar__container-content {
  background-color: white;
  overflow-x: hidden;
}

.svc-top-bar {
  background: var(--background-dim);
}

.svc-side-bar__container-header {
  background: var(--background-dim);
}

.spg-question[data-name="category"] select {
  margin: 2rem;
  width: 85%;
  border: none;
  border-bottom: 2px solid;
}

.spg-question[data-name="category"] select:focus {
  outline: none;
}

/* :not() used to seperate main dropdown (w/ General/Logic/etc options)
 with default language dropdown  */
.spg-input.spg-dropdown.sd-input--disabled:not(.sd-dropdown--empty) {
  margin: 2rem !important;
  width: 85% !important;
  border: none !important;
  border-bottom: 2px solid !important;
}

.spg-root-modern {
  overflow-y: auto;
}

.spg-table {
  border: 1px solid #d6d6d6;
}

.spg-table tr {
  border-bottom: 1px solid #d6d6d6;
}

.spg-table__cell {
  border: none;
}

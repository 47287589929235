.loading-wrapper {
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-root {
  min-width: 35rem;
  max-width: 100vw;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-root *:not(:first-child) {
  margin-top: 1rem;
}

.loading-root *:not(:last-child) {
  margin-bottom: 1rem;
}

.loading-root .header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
}

.loading-root .header svg {
  height: 50px;
  width: 200px;
  margin-bottom: 0;
}

.loading-root .MuiLinearProgress-root {
  margin-top: 0;
  width: 100%;
}

.headerText {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
}

.messageText {
  min-height: 1em;
}

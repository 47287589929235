.sv_main input[type="button"],
.sv_main button {
  color: white;
  background-color: var(--primary);
}
.sv_main input[type="button"]:hover,
.sv_main button:hover {
  background-color: var(--primary);
}

.sv_main .sv_body {
  background-color: white;
  border-top: 2px solid var(--primary);
}

.sv_main .sv_progress_bar {
  background-color: var(--primary);
}

/* .sd-root-modern {
  min-height: 100%;
} */

.sd-rating__item.sd-rating__item--disabled.sd-rating__item--selected {
  color: var(--primary-foreground, #fff);
}


.leftAlign span {
  justify-content: left;
  text-align: left;
}

.leftAlign div {
  justify-content: left;
  text-align: left;
}

.leftAlign {
  padding-left: 0px
}

.leftPadding {
  padding-left: 15px
}

.responseTable tbody tr.MuiTableRow-root:nth-child(even) {
  background-color: #eeeeee;
}
.responseTable tbody tr.MuiTableRow-root:nth-child(odd) {
  background-color: #ffffff;
}

.download-icon {
  border-radius: 5px;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    color: white;
    background-color: var(--primary);
}